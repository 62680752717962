const articles = [
  {
    id: "1",
    name: "Blockchain",
    title: "What is blockchain technology?",
    thumbnail: "/Images/proxy (1).jpeg",
    content: [
      `Blockchain technology is an advanced database mechanism that allows transparent information sharing within a business network. A blockchain database stores data in blocks that are linked together in a chain. The data is chronologically consistent because you cannot delete or modify the chain without consensus from the network. As a result, you can use blockchain technology to create an unalterable or immutable ledger for tracking orders, payments, accounts, and other transactions. The system has built-in mechanisms that prevent unauthorized transaction entries and create consistency in the shared view of these transactions.`,
    ],
  },
  {
    id: "2",
    name: "Palkadot",
    title: "What is Palkadot",
    thumbnail: "/Images/proxy (2).jpeg",
    content: [
      `Polkadot is envisioned as an improved version of Ethereum. Launched in May 2020, Polkadot is the brainchild of Ethereum (ETH) co-founder Gavin Wood. The platform is now run by the Web3 Foundation (W3F), and developed by Parity Technologies, both co-founded by Wood.

      Polkadot operates at a deeper level than a blockchain like Ethereum—think of it as providing a foundation on which others crypto projects can build. It calls itself a Layer 0 blockchain, whereas Ethereum and similar blockchains like Solana (SOL) and Cardano (ADA) are called Layer 1 blockchains.
      
      When Wood first wrote the Polkadot whitepaper, he argued that crypto needed a new system allowing interactions between different blockchain networks. Noticing issues with Ethereum’s ability to grow and scale, Wood attempted to solve this problem with a new blockchain based on a proof-of-stake validation system.
      
      Proof-of-stake validation was proposed in contrast to earlier blockchains, such as Bitcoin (BTC) or Litecoin (LTC). In proof of work, blockchain miners solve cryptographic puzzles to add the next block to the chain for mining rewards. With proof of stake, validators use tokens staked as collateral to determine the next block in the chain.
      
      The primary scaling issue that Wood wanted to address with Polkadot was the amount of computing power Ethereum required for its original proof-of-work validation system.
      
      `,
    ],
  },
  {
    id: "3",
    name: "learn-node",
    title: "How to Build a Node Server in 10 Minutes",
    thumbnail: "/Images/1000_F_518534996_FHFmf9ySfZLS5xmYgaEohI18xifwaEDM.jpeg",
    content: [
      `In this article, we're going to be talking looking at a very quick way to set up a Node.js server. We'll be discussing some topics such as proin congue ligula id risus posuere, vel eleifend ex egestas. Sed in turpis leo. Aliquam malesuada in massa tincidunt egestas. Nam consectetur varius turpis, non porta arcu porttitor non. In tincidunt vulputate nulla quis egestas. Ut eleifend ut ipsum non fringilla. Praesent imperdiet nulla nec est luctus, at sodales purus euismod.`,
    ],
  },
  {
    id: "4",
    name: "DApps",
    title: "What is DApps",
    thumbnail: "/Images/1000_F_518534996_FHFmf9ySfZLS5xmYgaEohI18xifwaEDM.jpeg",
    content: [
      `A decentralized application – or dapp – is like a digital app found on any smartphone or laptop, with the additional feature of employing blockchain technology to keep users’ data out of the hands of the organizations behind it. Just like cryptocurrency is decentralized money, dapps are decentralized apps.
      The blockchain stores copies of its expanding stack of data on a large number of participating computers, known as “ nodes ,” all at once. These computers are owned by users, not by the creators of the dapp. A full explanation of how blockchain technology works can be found here .
      Dapps are as varied as conventional apps: They can provide social networks, games, entertainment, productivity tools and so on. Many are designed as tools to help consumers access decentralized financial services, or DeFi .`,
    ],
  },
  
  
];

export default articles;
