import React from "react";
import { useNavigate } from "react-router-dom";


const AboutUs = () => {
    let nevigate = useNavigate();
    const nevigateTo = (e) =>{
      nevigate("/about");
      e.preventDefault()

    }

  return (
    <div>
      <div id="about" class="about">
        <div class="container">
          <div class="row">
            <div class="col-md-12 ">
              <div class="titlepage">
                {/* <h2>About Webereum</h2> */}
                                <h1>About Webereum</h1>

                {/* <span>
                  {" "}
                  In this blog we publish educational blogs, Web3 related blogs
                  and about all cryptocurrencies like bitcoin Ethereum and fear
                  and greed index of investers and traders. Users can add notes
                  after login or signup and store for a long time.
                </span> */}
              </div>
            </div>
          </div>
        </div>
        <div class="bg">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="about-box">
                  <p>
                    <span>
                      In this blog we publish educational blogs, Web3 related
                      blogs and about all cryptocurrencies like bitcoin Ethereum
                      and fear and greed index of investers and traders. Users
                      can add notes after login or signup and store for a long
                      time.
                      In this blog we publish educational blogs, Web3 related
                      blogs and about all cryptocurrencies like bitcoin Ethereum
                      and fear and greed index of investers and traders. Users
                      can add notes after login or signup and store for a long
                      time.
                      In this blog we publish educational blogs, Web3 related
                      blogs and about all cryptocurrencies like bitcoin Ethereum
                      and fear and greed index of investers and traders. Users
                      can add notes after login or signup and store for a long
                      time.
                    </span>
                  </p>
                  <div class="palne-img-area">
                    <img src="public/HomeImages/plane-img.png" alt="images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a 
          href="about" 
          className="mb-5 mt-3" onClick={nevigateTo}>
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
