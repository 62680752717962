import React from 'react'

const Notfound = () => {
  return (
    <div className="card container mt-5 bg-warning">
        <h1 className='container text-center mx-auto mb-5 mt-5'>
            404:Page Not Found
        </h1>
          {/* <h1 className='container text-center mx-auto mb-5'>
            Somthing Went Wrong
        </h1> */}

      
    </div>
  )
}

export default Notfound;
